import React, { useState } from "react";
import "./qualification.css";

const Qualification = () => {
    const [toggleState, setToggleState] = useState(2);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <section className="qualification section" id="qualification">
            <h2 className="section__title">Qualifications</h2>
            <span className="section__subtitle">My Personal Journey </span>

            <div className="qualification__container container">
                <div className="qualification__tabs">
                    <div
                        className={
                            toggleState === 1
                                ? "qualification__button button--flex qualification__active"
                                : "qualification__button button--flex"
                        }
                        onClick={() => toggleTab(1)}
                    >
                        <i className="uil uil-graduation-cap qualification__icon"></i>{" "}
                        Education
                    </div>
                    <div
                        className={
                            toggleState === 2
                                ? "qualification__button button--flex qualification__active"
                                : "qualification__button button--flex"
                        }
                        onClick={() => toggleTab(2)}
                    >
                        <i className="uil uil-briefcase-alt qualification__icon"></i>{" "}
                        Experience
                    </div>
                </div>

                <div className="qualification__sections">
                    <div
                        className={
                            toggleState === 1
                                ? "qualification__content qualification__content-active"
                                : "qualification__content"
                        }
                    >
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">M.E.</h3>
                                <span className="qualification__subtitle">
                                    BITS PILANI, PILANI
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>{" "}
                                    2019 - 2021
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>
                        <div className="qualification__data">
                            <div></div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                            <div>
                                <h3 className="qualification__title">
                                    B.TECH.
                                </h3>
                                <span className="qualification__subtitle">
                                    IIIT VADODARA
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>{" "}
                                    2014 - 2018
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            toggleState === 2
                                ? "qualification__content qualification__content-active"
                                : "qualification__content"
                        }
                    >
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">
                                    Software Engineer
                                </h3>
                                <span className="qualification__subtitle">
                                    AmyGB
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i> Feb
                                    2022 - June 2023
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>
                        <div className="qualification__data">
                            <div></div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                            <div>
                                <h3 className="qualification__title">
                                    DevOps Intern
                                </h3>
                                <span className="qualification__subtitle">
                                    Zeotap
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i> Aug
                                    2021 - Jan 2022
                                </div>
                            </div>
                        </div>
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">
                                    Software Engineer Intern
                                </h3>
                                <span className="qualification__subtitle">
                                    Walmart
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i> Jan
                                    2021 - Mar 2021
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>
                        <div className="qualification__data">
                            <div></div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                            <div>
                                <h3 className="qualification__title">
                                    Software Engineer Intern
                                </h3>
                                <span className="qualification__subtitle">
                                    Walmart
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i> May
                                    2020 - Jul 2020
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Qualification;

import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer__container container">
            <h1 className="footer__title">Mayank Maurya</h1>

            <ul className="footer__list">
                <li>
                    <a href="#about" className="footer__link">About</a>
                </li>
                <li>
                    <a href="#qualification" className="footer__link">Qualifications</a>
                </li>
            </ul>

            <div className="footer__social">
                <a href="https://www.linkedin.com/in/maurya-mayank/" className="footer__social-link" target="_blank">
                <i class='bx bxl-linkedin-square' ></i>
                </a>
                <a href="https://github.com/mayankpi" className="footer__social-link" target="_blank">
                    <i className="bx bxl-github"></i>
                </a>
            </div>

            <span className="footer__copy">
                &#169; 2023 Mayank Maurya. All right reserved
            </span>
        </div>
    </footer>
  )
}

export default Footer